body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  padding: 0;
  margin: 0;
}
.btn-peq {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.btn-ios {
  padding: 1rem;
  text-align: center;
  color: #0d6efd;
}

.btn-ios:hover {
  background: #e9ecef;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

